.step1{

  .choix{
    display: flex;
    flex-direction: column;
    @media screen and (min-width: #{$sm-screen}) {
      flex-direction: row;
    }

    section{
      cursor: pointer;
    }
    button{
      cursor: pointer;
    }

  }

  .choix-2-options{
    display: flex;
    flex-direction: column;


    @media screen and (min-width: #{$md-screen}) {
      flex-direction: row;
    }

    // section{
    //   width: 100%;
    // }
    //
    // section:nth-child(2){
    //   width: 500px;
    // }

  }


  section{
    // display: flex;
    // flex-direction: column;
    border-radius: $radius;
    margin: 0 $margin-md $margin-sm $margin-md;

    @media screen and (min-width: #{$sm-screen}) {
      margin: $margin-sm;
    }

    &.disabled{
      opacity: 0.5;
      button{
        pointer-events: none;
      }
    }

    &.choix-2-option-1,
    &.choix-2-option-2{
      min-width: auto;
      @media screen and (min-width: #{$sm-screen}) {
        min-width: 330px;
      }
    }

    button{
      margin-top: $margin-sm;
      min-width: 200px;
    }

    h4{
      margin: $margin-xs 0 $margin-sm 0;
    }

    &.choix-2-option-1{
      display: flex;
      // flex:1;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 !important;

      >div:nth-child(1){
        padding: $margin-sm $margin-md;
      }

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: row;
      }

      .css-2b097c-container{
        margin: $margin-sm 0;
      }

    }

    .option-1{
      display: flex;
      flex-direction: column;

      @media screen and (min-width: #{$sm-screen}) {
        width: 50%;
        margin-top: 0;
      }

      >div{
        display: flex;
        flex:1;
        align-items: center;
        background-color: $md-grey;
        color: $white;
        padding: $margin-md;
        cursor: pointer;

        img{
          width: 40%;
          margin-right: $margin-sm;
        }


        @media screen and (min-width: #{$sm-screen}) {
          margin: 0;
          padding: $margin-md;
        }

        &:first-child{
          border-radius: $radius $radius 0 0;
          margin-top: 0;
        }

        &:last-child{
          border-radius: 0 0 $radius $radius;
        }


        &.selected{
          background-color: $primary-color;
        }
      }
    }

    &.choix-2-option-2{
      max-width: 330px;
      .input-dimension{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .container-input{
          width: 100px;
        }
        span{
          padding: 0 5px;
        }
      }
    }

  }
}
