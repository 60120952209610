.informations{
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  .informations-navigation{
    position: absolute;
    width: 100%;
    z-index: 1;
    text-align: center;
    padding: $margin-xs 0 $margin-sm 0;
    height: $height-nav;
    white-space: nowrap;
    transition: all .4s ease-in-out;

    @media screen and (min-width: #{$md-screen}) {
      padding: $margin-md 0 $margin-sm 0;
    }

    a{
      font-family: "MontSerrat-SemiBold";
      color : $text-color;
      text-decoration: none;
      margin: 0 2%;
      @include remove-tap-highlight;

      &.active{
        color: $primary-color;
      }
    }
  }
  .support{
    justify-content: center !important;
    padding-top: 0 !important;
    padding-bottom: $margin-sm !important;
    button{
      margin: 0;
    }
  }

  .container-informations{
    position: absolute;
    width: 100%;
    top:0px;
    min-height: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding-top: $margin-md;

    @media screen and (min-width: #{$md-screen}) {
      padding-top: calc(#{$height-nav} - #{$margin-sm});
    }

    >.content{
      @media screen and (max-width: #{$md-screen}) {
        padding-bottom: $margin-xs;
      }
    }


  }

  .content.large{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: inherit;
    padding: $margin-md;

    @media screen and (min-width: #{$md-screen}) {
      flex-direction: row;
      flex-wrap: wrap;

    }

    .fullwidth{
      width: 100%;
      text-align: center;
      margin: $margin-sm 0;
      b{
        font-family: "MontSerrat-SemiBold";
        color : $secondary-color;
      }
    }

  }

  .col{
    width: calc(100%);
    @media screen and (min-width: #{$md-screen}) {
      width: calc(33.33% - #{$margin-xs});
    }

    .container-files{
      margin-top: 0;
      margin-bottom: $margin-sm;
    }

  }

  .block{
    @include block();
    .email,
    .email a,
    .tel,
    .tel a,
    b{
      font-family: "MontSerrat-SemiBold";
      color: $secondary-color;
      text-decoration: none;
      margin: 0;
      line-height: 20px;
    }

    .web a{
      font-family: "MontSerrat-SemiBold";
      color: $secondary-color;
      margin: 0;
      line-height: 20px;
      text-decoration: underline;
    }

    .new{
      position: relative;
      background-color: lighten($secondary-color,55%);
      padding: $margin-xs $margin-sm $margin-sm $margin-sm ;
      border-radius: 8px;
      margin: 20px 0 0 0;
      label{
        display: inline-block;
        margin-bottom: $margin-xs;
        >span{
          display: inline-block;
          font-family: "MontSerrat-SemiBold";
          font-size: 16px;
        }
      }
      .flash{
        position: absolute;
        display: inline-block;
        top: -8px;
        left: -18px;
        width: 30px;
        padding: 0;

        img{
          width: 100% !important;
          margin-bottom: 0 !important;
        }
        // &:after{
        //   position: absolute;
        //   font-family: "MontSerrat-SemiBold";
        //   font-size: 16px;
        //   content : "Nouveau";
        //   left:35px;
        //   top:5px;
        // }
      }
    }

    .semibold{
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .email{
      margin-top: 3px;
    }
  }

}
