.step2{
  section{
    margin: 0 $margin-md $margin-sm $margin-md;
    border-radius: $radius;

    @media screen and (min-width: #{$sm-screen}) {
      margin: $margin-sm;
    }
  }

  .stand-info {
    margin-top: $margin-sm;
    padding: $margin-md;
    @media screen and (min-width: $sm-screen) {
      margin-top: 0;
    }

    .container-input label {
      font-family: "Montserrat-SemiBold";
    }

    .sub {
      color: $text-disabled-color;
    }

  }

  .shape{
    ul{
      display: flex;
      flex-direction: column;
      padding: 0;
      border-radius: $radius;
      overflow: hidden;

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: row;
      }

      li{
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        list-style: none;
        background-color: $secondary-color;
        padding: $margin-sm $margin-sm;

        @media screen and (min-width: #{$sm-screen}) {
          flex-direction: column;
        }

        p{
          font-family: "Montserrat-Bold";
          font-style: $h2;
          text-transform: uppercase;
          color:white;
          box-sizing: border-box;
          padding: 0 $margin-sm;
        }

        img{
          max-height: 90px;
          margin-bottom: 0;
          @media screen and (min-width: #{$sm-screen}) {
            height: 130px;
            max-height: 130px;
            margin-bottom: $margin-sm;
          }
        }
        &.selected{
          background-color: $primary-color;
        }
      }
    }

    section{
      padding: 0;
    }

    .warn-shape{
      font-family: "Montserrat-SemiBold";
      img{
        position: relative;
        top:2px;
        margin-right: 5px;
      }
    }

    .sub {
      font-size: $small;
      margin-left: $margin-md;
      @media screen and (min-width: $sm-screen) {
        margin-left: $margin-sm;
      }
    }

  }

  .list-options{

    h2{
      padding: $margin-md;
      line-height: 30px;
      @media screen and (min-width: #{$sm-screen}) {
        padding: $margin-md $margin-sm $margin-sm $margin-sm;
      }
    }

    .container-total{
      padding: 0 $margin-md 0 $margin-md;
      text-align: right;

      @media screen and (min-width: #{$sm-screen}) {
        padding: 0 $margin-sm 0 $margin-sm;
      }

      label{
        font-family: "Montserrat-SemiBold";
        color : $secondary-color;
        font-size: $medium;
        text-transform: uppercase;
        .total{
          font-family: "Montserrat-SemiBold";
          color : $primary-color;
        }
      }
    }

    .container-switch{
      .switch-field{
        margin: $margin-md 0;
      }
    }

    .options-water{
      display: flex;
      align-items: center;
      @media screen and (max-width: #{$sm-screen}) {
        flex-direction: column;
        align-items: flex-start;
        a{
          margin-left: 0 !important;
        }
      }
      a{
        margin-left: $margin-sm;
        color: $text-disabled-color;
      }
    }

    .options-wifi {
      ul {
        padding-left: $margin-sm;
        font-size: $small;
        li {
          line-height: 18px;
          color: $text-disabled-color;
        }
      }
      p:last-child {
        color: $primary-color;
      }
    }

    .standiste{
      padding: $margin-md 0;

      .section-title{
        padding: 0;
      }

      .container-inputs{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .container-input{
          width: 100%;
        }
        @media screen and (min-width: #{$sm-screen}) {
          flex-direction: row;
          .container-input{
            width: calc(33% - 5px);
          }
        }
      }

    }
  }

}
