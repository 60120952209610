@import './list-products-orders.scss';

.order{
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;
  overflow-y: auto;
  box-sizing: border-box;

  @include inputNumber();

  .provider{
    font-size: $regular;
    font-family: "MontSerrat-SemiBold";
    color: $secondary-color;
    margin: 0 $margin-sm $margin-sm $margin-sm;
    @media screen and (min-width: #{$md-screen}){
      font-size: $medium;
      margin: 0 $margin-sm $margin-xs 0;
    }
    @media screen and (max-width: #{$md-screen}){
      margin-left: 20px;
    }
  }

  .loader-order{
    position: relative;
    padding: $margin-md 0;
    >div{
      position: relative !important;
    }
  }

  .container-btn-validate{
    background-color: $secondary-color;
    padding: $margin-md;
    border-radius: 0 0 12px 12px;
    box-shadow: none;
    text-align: center;
    margin: $margin-sm 0;

    button{
      margin-bottom: $margin-md;
    }
    >div{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color : white;
      p{
        margin: 0 $margin-xs;
        text-align: left;
      }
    }
    @media screen and (min-width: #{$sm-screen}){
      text-align: left;
    }
    @media screen and (min-width: #{$md-screen}){
      border-radius: $radius;
      @include shadow();
    }
  }

  @media screen and (max-width: #{$md-screen}){

    .container-order{
      border-radius: $radius;
      @include shadow();
      // overflow: hidden;
      background-color: $white;
      h2{
        position: relative;
        display: inline-block;
        margin: $margin-sm;
        font-size: $medium;
        padding-bottom: $margin-xs;
        @media screen and (max-width: #{$md-screen}){
          margin-left: 20px;
        }
        &:after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          height: 2px;
          width: 50%;
          background-color: $primary-color;
        }
      }
    }

    .list-products ul{
      margin: 0;

      li div{
        margin-bottom: 0;
        box-shadow: none;
        border-radius: 0;
      }

      li>div{
        padding-top: 0;
        @media screen and (max-width: #{$md-screen}){
          padding-left: 20px;
        }
      }

      // .discount

      li:not(:last-child) .product-qty{
        border-bottom: solid 1px $md-grey;
        padding-bottom: $margin-sm;
      }

      .product-qty .only-on-tablet{
        display: block;
      }

    }

    @media screen and (max-width: #{$sm-screen}){

      .list-products{
        padding: 0;
      }

      .container-order{
        margin: 0 $margin-sm $margin-sm $margin-sm;
      }

    }

  }

}
