input{
  /* Remove inner shadow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;

  padding: $margin-xs;
  border: solid 1px $md-grey;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border-radius: $radius;
  outline: none;
  @media screen and (min-width: #{$md-screen}) {
    max-width: 450px;
    max-width: 450px;
  }
  &:not(:disabled){
    border: solid 1px $md-grey;
    color: $text-color;
  }
  &:disabled{
    border-color: lighten($text-disabled-color, 40%);
  }
}
