.horaires{
  .block{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto $margin-sm auto;

    >div{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: $margin-md 0 0 0;
    }

    .info{
      font-family: "Montserrat-SemiBold";
      color : $primary-color;
      margin-top: $margin-sm !important;
    }

    .sub{
      font-style: italic;
      color : $text-disabled-color;
      margin-top: $margin-sm !important;
    }

    @media screen and (min-width: #{$sm-screen}){
      flex-direction: row;

      >div{
        width: 25%;
        margin: 0;
      }
    }

    >h3{
      align-self: flex-start;
      font-size: 16px;
      width: 100%;
      margin-bottom: $margin-md;

      @media screen and (min-width: #{$md-screen}){
        font-size: $medium;
        width: 25%;
        margin: 0;
      }

    }

    >div{
      flex: 1 2;
      img{
        margin: 0 auto;
        min-width: 40px;
      }
      p{
        text-align: center;
        margin: $margin-sm;
      }
    }

  }

  .block:nth-child(1){
    >div img{
      width: 50px;
      min-height: 50px;
      display: block;
      margin: 0 auto;
    }
  }

  .block:nth-child(2){

    // div:nth-child(2){
    //   background-color: red;
    // }


    div:nth-child(2) {
      @media screen and (min-width: $sm-screen){
        max-width: 300px;
        padding-right: $margin-md;
      }

      @media screen and (min-width: $md-screen){
        max-width: 300px;
      }
      img{
        display: block;
        width: 100%;
        margin: 0;


        &:first-child{
          display: block;
          @media screen and (min-width: $sm-screen){
            display: none;
          }
        }

        &:last-child{
          display: none;
          @media screen and (min-width: $sm-screen){
            display: block;
          }
        }

      }

    }
    // div:nth-child(3){
    //   flex-direction: row;
    //   p{
    //     text-align: left;
    //   }
    //   @media screen and (min-width: #{$sm-screen}){
    //     flex-direction: column;
    //     img{
    //       margin: 0 auto;
    //     }
    //     p{
    //       text-align: center;
    //     }
    //   }
    //   img{
    //     margin: 0;
    //     width: 50px;
    //     min-height: 50px;
    //     display: block;
    //   }
    // }

    div:last-child{
      @media screen and (min-width: $sm-screen){
        width: 50%;
      }
      p{
        text-align: left;
        margin: 0;
      }
      h3{
        width: 100%;
        font-size: 16px;
        &:after{ display: none;}
        @media screen and (min-width: #{$sm-screen}){
          font-size: $regular;
        }
      }
    }



  }

}
