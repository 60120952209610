.upload-doc{
  .hide{
    display: none;
  }

  .format{
    margin: 0;
    margin-top: -7px;
    color: $md-grey
  }

  .error-file {
    color: $error;
    font-size: $small;
  }

}
