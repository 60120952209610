.done-quizz{
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  h1{
    position: relative;
    color: $text-color;
    text-align: center;
    padding-bottom: $margin-sm;
    margin-bottom: 40px;
    &:after{
      content : "";
      position: absolute;
      height: 2px;
      width: 50%; max-width: 100px;
      bottom: 0;
      left:50%;
      transform: translateX(-50%);
      background-color: $primary-color;
    }
  }

  p{
    text-align: center;
    font-family: "Montserrat-SemiBold";
  }

  @media screen and (min-width: 455px){
    width:425px;
  }

  .btn-backward{
    padding: 0;
    min-width: 100px;
    margin-top: $margin-md;
  }

}
