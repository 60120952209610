.contributors{
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: $margin-sm;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }


  @include sectionTitle();
  @include addContact();
  @include lineUser();

  .line-user{
    flex-wrap:nowrap;
    transition: opacity .4s ease-in-out,transform .4s ease-in-out;
    border-radius: $radius;

    @media screen and (min-width: #{$md-screen}) and (max-width: "1260px") {
      align-items: stretch;
    }

    @media screen and (max-width: #{$sm-screen}) {
      flex-direction: column-reverse;
      padding: 0;
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: #{$sm-screen}) and (max-width: #{$md-screen}) {
      padding: 0;
      >div{
        padding: $margin-sm !important;
      }

      .container{
        background-color: $secondary-color;
        color : white;
        border-radius: $radius 0 0 $radius;
        width: 100%;
        max-width: 33%;
        @media screen and (max-width: #{$sm-screen}) {
          border-radius: 0;
        }
      }

    }

    .name {
      font-family: "Montserrat-SemiBold";
    }

    .edit{
      flex-wrap:nowrap;

      img{
        position: relative;
        top:1px;
      }

      @media screen and (max-width: #{$md-screen}) {
        width: auto;
      }
      @media screen and (max-width: #{$sm-screen}) {
        justify-content: space-between;
        background: $secondary-color;
        margin-bottom: $margin-sm;
        border-radius: $radius $radius 0 0;
        color : white;

        img{
          position: relative;
          top:1px;
        }

        .user{
          display: block;
          width: 100%;
          p{
            &.name {
              margin-bottom: $margin-xs;
            }
            &:not(.name){
              font-size: $small;
            }
            margin: 0;
            color : white;
          }
        }
      }

      .toggle-edit{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        label{
          display: none;
          text-decoration: underline;
          margin: 0 7px 0 0;
          cursor: pointer;
          &:hover{
            color : $secondary-color
          }
          @media screen and (min-width: #{$md-screen}){
            display: inline-block;
          }
        }

        &:not(:hover) img:nth-child(3){
          display: none;
        }

        &:hover img:nth-child(2){
          display: none;
        }

        img {
          position: relative;
          top:1px;
          width: 14px;

        }
      }

    }

    &.fade-out{
      opacity: .4;
      transform: scale(0.9);
    }

    .presence-days{
      width: 100%;

      ul{
        display: flex;
        flex-direction: column;
        flex-wrap:nowrap;
        justify-content: center;
        align-items: flex-start;


        @media screen and (min-width: #{$md-screen}) and (max-width: "1260px") {
          align-items: center;
        }

        @media screen and (min-width: "1260px") {
          flex-direction: row;
        }
        li{
          padding: 4px 8px;
          border-radius: 14px;
          margin: 0 3px;

          &:not(.day){

            @media screen and (min-width: #{$sm-screen}){
              margin-bottom: $margin-xs;
              padding: 0;
            }
            @media screen and (max-width: #{$sm-screen}){
              margin: 0 3px 3px 3px;
            }
          }

          &.day{
            font-size: $small;

            @media screen and (max-width: #{$sm-screen}){
              margin: 0 0 $margin-xs $margin-xs !important;
            }

            @media screen and (min-width: #{$sm-screen}) and (max-width: "1260px") {
              &:not(:last-child){
                margin: 0 3px $margin-xs 3px !important;
              }
            }

            &.disabled{
              border: solid 1px $text-disabled-color;
              opacity: 0.3;
              p,
              p span{
                color: $text-disabled-color;
              }
            }


            &:not(.disabled){
              border: solid 1px $secondary-color;
              background-color: $secondary-color;
              p,
              p span{
                color: white;
              }
            }
          }

          p.cocktail{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              position: relative;
              top:-2px;
            }
          }
          white-space: nowrap;
        }
      }
    }
  }

  .module-contact{
    position: relative;
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: $white;
    margin-bottom: $margin-md;
    padding: $margin-sm $margin-sm 0 $margin-sm;
    border: solid 2px $secondary-color;
    $shadow: 0px 10px 13px -2px rgba(156,167,177,0.55);

    @media screen and (min-width: #{$sm-screen}) {
      padding: $margin-md $margin-md 0 $margin-md;
    }

    @media screen and (min-width: #{$md-screen}) {
      padding: $margin-lg  $margin-lg  0  $margin-lg;
    }

    @media screen and (max-width: #{$sm-screen}) {
      border-left: none;
      border-right: none;
    }


    @media screen and (min-width: #{$sm-screen}) {
      border-radius: $radius;
    }

    .grid-form{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      grid-gap: 0 $margin-md;
      width: 100%;

      @media screen and (min-width: #{$sm-screen}) {
        grid-template-columns: repeat(2,1fr);
      }

      @media screen and (min-width: #{$md-screen}) {
        grid-template-columns: repeat(3,1fr);
      }

    }

    // .container-input,
    // .container-email{
    //   width: 100%;
    //
    //   @media screen and (min-width: #{$sm-screen}) {
    //     width:49%;
    //   }
    //   @media screen and (min-width: 1100px) {
    //     width:32%;
    //   }
    //
    // }

    .container-phone{
      .error input{
        border-color: #B2B2B2;
      }
    }

    .container-email .container-input{
      width: 100%;
    }


    .container-switch{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      @media screen and (min-width: #{$sm-screen}) {
        flex-wrap: wrap;
      }
      >div{
        margin-right: $margin-sm;
      }
      img{
        position: relative;
        top:2px;
        width: 16px;
        margin-left: 5px;
      }
      .required{
        color : $primary-color;
      }
    }



    .container-select-items{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: $margin-sm 0 0 0;
      width: 100% !important;


      label{
        margin-right: $margin-sm;
      }

      @include loader();
      .loader{
        position: relative;
        top:-5px;
      }

      p{
        margin: 0;
        margin-right: $margin-sm;
        width: 100%;
      }

    }


    .container-button{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: -35px;
      margin-left: -25px;
      margin-right: -25px;
      width: calc(100% + 50px);

      @media screen and (min-width: #{$sm-screen}) {
        margin-bottom: -35px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }

      @media screen and (min-width: #{$md-screen}) {
        padding: $margin-lg;
        margin-bottom: -85px;
      }

      button{
        margin: $margin-sm $margin-xs;
        min-width: inherit;

        &.secondary{
          background-color: white;
        }
      }
    }


  }

}
