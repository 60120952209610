// SCREEN WIDTH

$xs-screen : 360px;
$sm-screen : 767px;
$md-screen : 1224px;
$md-nav-screen : 1144px;

// FONTS

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "MuseoSans-700";
	src: url("../assets/fonts/MuseoSans-700.otf") format("truetype");
}

@font-face {
	font-family: "MuseoSans-900";
	src: url("../assets/fonts/MuseoSans-900.otf") format("truetype");
}


@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Medium";
	src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
	font-family: "LucidaGrande-Bold.ttf";
	src: url("../assets/fonts/LucidaGrande-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "LucidaGrande";
	src: url("../assets/fonts/LucidaGrande.ttf") format("truetype");
}

//COLORS
$text-color : #161B46;
$text-disabled-color : #797C94;
$white : #FFFFFF;
$green : #32a860;
$light-grey : #F9F9FB;
$md-grey : #B2B2B2;
$primary-color : #FF8900;
$secondary-color : #005FBF;
$dark-blue : #004289;
$error : #FF0009;

//METRICS
$radius : 12px;
$small : 12px;
$regular : 14px;
$medium : 20px;
$xl : 49px;
$h2 : 24px;
$h1 : 28px;
$margin-lg : 45px;
$margin-md : 30px;
$margin-sm : 15px;
$margin-xs : 10px;
$header-height: 90px;

$shadow: 0px 0px 13px -2px rgba(156,167,177,0.30);
