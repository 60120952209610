.plan{
  button{
    z-index: 10;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    right: 20px;
    margin: 0;
    padding: 0 $margin-sm;
    img{
      width: 20px;
      margin-right: 10px;
    }
  }

  svg{
    cursor: grab;
    // font-family:
    tspan,text{
      font-family: "LucidaGrande";
    }
  }
}
