.reset-password{
  .sub{
    font-size: 12px;
  }
}

.select-type,
.login,
.forgot-password,
.reset-password{
  height: 100%;

  .content{
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;

    padding: $margin-md;
    width: 100%;
    max-width: 100%;

    @media screen and (min-width: #{$md-screen}) {
      padding: 0;
    }

    .address{
      display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-md;
      img{
        width: 80px;
        margin: 0 $margin-sm ;
      }

      @media screen and (min-width: #{$md-screen}) {
        img{
          width: 110px;
        }
      }

      p{
        text-align: left;
        color: $secondary-color;
        font-family: "Montserrat-SemiBold";
        margin: 0 $margin-xs;

      }
    }


  }

  .content .left{
    display: none;
    position: relative;
    height: 100%;
    max-height: 100%;
    background-color: #0F1B37;
    @media screen and (min-width: #{$md-screen}) {
      margin-top: 0;
      display: block;
    }

    img:first-child {
      max-width: 649px;
      height: 100%;
      object-fit: contain;
    }

    // img:last-child{
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   max-width: 649px;
    // }

  }

  .content .form-login{
    height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    margin-top: calc(-1 * #{$margin-md});

    @media screen and (min-width: #{$md-screen}) {
      margin-top: 0;
      width: 50%;
    }

    a{
      text-decoration: none;
    }

    >img{
      width: 60px;
      @media screen and (min-width: #{$md-screen}) {
        width: 90px;
      }
    }

    .field-icon{
      height: 40px;
      label{
        display: none;
      }
      input{
        margin: 0;
      }
    }

    .field-icon{
      margin: 5px 0;
    }

    button{
      margin-top: 5px;
    }

    @media screen and (min-width: #{$sm-screen}) {
      .field-icon{
        margin: 10px 0;
      }
      button{
        margin-top: 10px;
      }

    }



    h1{
      font-family: "Montserrat-Bold";
      margin-bottom: $margin-xs;
      font-size: $h2;
      @media screen and (min-width: #{$sm-screen}) {
        font-size: $h1;
      }
    }

    h1,h2,a{
      color: $secondary-color;
    }


    h2{
      margin-top: 0;
      margin-bottom: $margin-md;
      font-size: $regular;
      @media screen and (min-width: #{$sm-screen}) {
        font-size: $medium;
      }
    }

  }

}

.select-type{
  a{
    text-decoration: none;
  }
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    background-color: $secondary-color;
    border-color : $secondary-color;
    margin: $margin-md auto;
    padding: $margin-sm $margin-md;
    height: auto;

    img{
      display: inline-block;
      margin-right: $margin-sm;
      width: 18px;
    }

    img:last-child{
      display: none;
    }

    &:hover{
      img:first-child{
        display: none;
      }
      img:last-child{
        display: inline-block;
      }
    }


  }
}
