.select-quizz{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: $margin-md auto 0 auto;
  box-sizing: border-box;

  h1{
    position: relative;
    color: $text-color;
    text-align: center;
    padding-bottom: $margin-sm;
    margin-bottom: 40px;
    &:after{
      content : "";
      position: absolute;
      height: 2px;
      width: 50%; max-width: 100px;
      bottom: 0;
      left:50%;
      transform: translateX(-50%);
      background-color: $primary-color;
    }
  }

  p{
    text-align: center;
    margin-bottom: $margin-md;
  }

  button{
    width: 186px;
  }



  .rules a{
    color: $text-color;
  }

}
