/// FORMULAIRE ///

.field-icon{
  position: relative;
  width: 100%;
  max-width: 450px;
  img{
    position: absolute;
    z-index: 2;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  input{
    padding-right: 50px;
  }
}

.container-input{
  width: 100%;

  label{
    display: block;
  }

  >input{
    display: inline-block;
    width: 100%;
  }

  &.error{
    input{
      border-color: $error;
    }
  }

}

textarea{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;

  padding: $margin-xs;
  border: solid 1px $md-grey;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  border-radius: $radius;
  outline: none;
  resize: none;
}

/// TOOLTIP ///

.MuiTooltip-tooltip{
  background-color: $md-grey !important;
}

.tooltip-box{
  background-color: $primary-color;
  height: 40px !important;
  width: 40px !important;
  img{
    width: 30px;
  }
}

.tooltip,.tooltip-box{
  display: inline-flex;
  vertical-align: bottom;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 5px;
  font-family: "MontSerrat-SemiBold";
  @include remove-highlight();
  @include remove-tap-highlight();

  img{
    position: relative;
    top:2px;
    width: 22px;
  }

}

.content-tooltip{
  padding: $margin-sm;
  font-size: $regular;
  line-height: 18px;
  p{
    line-height: 18px;
    font-size: $regular;
    margin: 0;
  }

  ul{
    padding: 12px;
    margin: 0;
  }
}

/// BUTTON ///

button{
  padding: 0 $margin-md;
  font-size: $regular;
  height: 45px;
  border-radius: 22.5px;
  margin-bottom: $margin-sm;
  min-width: 150px;
  outline: none;
  transition: all .3s ease-in-out;
  @include remove-tap-highlight;

  &:not(.disabled){
    cursor: pointer;
  }

  &.disabled{
    border: solid 1px rgba(200,200,205,1) !important;
    background-color: rgba(200,200,205,1) !important;
    pointer-events: "none";
  }

  &:not(.secondary){
    background-color: $primary-color;
    border: solid 1px $primary-color;
    color: $white;
    &:hover{
      border: solid 1px darken($primary-color,3%);
      background-color: darken($primary-color,3%);
    }
  }

  &.secondary{
    background-color: transparent;
    border: solid 1px $secondary-color;
    color: $secondary-color;
    &:hover{
      border: solid 1px darken($secondary-color,3%);
      color: darken($secondary-color,3%);
    }
  }

  @include loader();

  .loader{
    margin-right:-10px;
  }


}

.progress-saved{
  position: absolute;
  background-color: $md-grey;
  height: 10px !important;
  bottom: 0;
  .bar{
    height: 10px !important;
    background-color: $primary-color;
    transition: all .4s ease-in-out;
  }
}

/// CENTERED ///

.centered{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.app-loading{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flash{
  img{
    animation : spin 10s infinite;
    animation-timing-function: linear;
    width: 100%;
  }
  @keyframes spin {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
  }
}

.container-reglement{
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  .overlay{
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    opacity: .6;
    background-color: #1E2F66;
  }

  .modal{
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-width: 425px;
    max-height: 80%;
    background-color: white;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: $margin-md;
    border-radius: 10px;
    overflow-y: auto;
    h3{
      margin-top: 0;
    }

    img{
      position: absolute;
      top:10px;
      right:10px;
    }

  }

}
