.validate-order{
  position: fixed;
  top:0;
  z-index: 1002;
  width: 100%;
  height: 100%;

  .modal{
    position: fixed;
    top:50%;
    left:50%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding-top: 60px;
    background-color: $white;
    border-radius: 0;
    transform: translate(-50%,-50%);

    @media screen and (min-width: #{$sm-screen}) {
      padding-top: 0px;
      max-width: 90%;
      max-height: 90%;
      border-radius: $radius;
      @include shadow();
    }

    @media screen and (min-width: #{$md-screen}) {
      max-width: 600px;
      max-height: 600px;
    }

    @include btnClose();
    .button-close{
      position: absolute;
      z-index: 100;
      right:15px;
      top:15px;
      @media screen and (min-width: #{$md-screen}) {
        right:0;
        top:0;
        transform: translate(50%,-50%);
      }
    }

    .scanner{
      position: relative;
      width: 100%;
      padding: $margin-sm;
      overflow: hidden;
      .container-btn{
        position: absolute;
        z-index: 1;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 220px;
      }
    }

    .result-validate{
      position: absolute;
      top:50%;
      left:50%;
      width: 70%;
      max-width: 420px;
      transform: translate(-50%,-50%);
      text-align: center;

      p{
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
        margin-bottom: $margin-md;
      }
      img{
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 300px;
        animation-iteration-count: 1;
        animation-duration: .6s;
        animation-name: scaleIn;
      }

      button{
        margin-top: $margin-md;
        margin-bottom: 0;
      }

      @keyframes scaleIn {
          0% {
            transform: scale(.2);
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            transform: scale(1);
          }
      }

    }

    button.blue{
      background-color: $secondary-color;
      border-color: $secondary-color;
    }

    .container-btn{
      text-align: center;
      @media screen and (min-width: #{$md-screen}) {
        button{
          position: absolute;
          z-index: 1;
          left:50%;
          transform: translate(-50%,-50%);
        }
      }
    }



  }

  .overlay{
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: $secondary-color;
  }

}

.validate-order.mobile.done{
  position: relative;
  z-index: 0;
  .overlay{
    display: none;
  }

  .modal{
    position: relative;

    .result-validate{
      position: relative;
      left: 0; top:0;
      transform: none;
      @include shadow();
      width: calc(100% - (#{$margin-sm}*2));
      padding: $margin-md;
      border-radius: $radius;
      margin: 0 $margin-sm;
    }

  }

}

.container-validate-order-mobile{
  position: relative;
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;

  .container-btn{
    position: absolute;
    bottom:30px;
    left:50%;
    transform: translate(-50%,0);

    button{
       min-width: 200px;
       line-height: 20px;
       height: 60px;
    }

    button.blue{
      background-color: $secondary-color;
      border-color: $secondary-color;
    }

  }



  .result-validate{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    @include shadow();
    width: calc(100% - (#{$margin-sm}*2));
    padding: $margin-md;
    border-radius: $radius;
    background-color: $white;
    text-align: center;
    a{
      margin-top: $margin-sm;
    }
  }

}

.container-validate-order{
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;

  .modal{
    padding-top: 0;
  }

  button{
    margin-bottom: 0;
    margin: $margin-sm;
    white-space: nowrap;
  }
}
