.montage{
  $md-large-screen : 1200px;
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;

  @media screen and (min-width: #{$md-large-screen}){
    padding: $margin-md;
    height: calc(100% - 90px) !important;
  }

  .content{
    max-width: 1220px;
  }

  .title-page{
    padding-top: 0;
    margin: 0 $margin-md;
    h2{
      margin-top: 0;
    }
  }

  .mobile-nav{
    position: absolute;
    width: 100%;
    z-index: 1;
    text-align: center;
    height: $height-nav;
    padding: $margin-sm 0 $margin-sm 0;
    white-space: nowrap;
    transition: all .4s ease-in-out;

    ul{
      padding: 0;
      margin: 0;
      li{
        display: inline;
        font-family: "MontSerrat-SemiBold";
        color : $text-color;
        text-decoration: none;
        margin: 0 2%;
        &.active{
          color: $primary-color;
        }
      }
    }
  }

  .container-animated{
    position: absolute;
    width: 100%;
    top: 0px;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &.paddingTop{
      padding-top: $margin-md;
    }
  }

  >.content{
    @media screen and (min-width: #{$md-large-screen}){
      display: flex;
      flex-wrap: wrap;
    }
  }

  .content .col{
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(50% - #{$margin-xs});

    h3{
      font-family: "MontSerrat-SemiBold";
      font-size: $regular;
      text-transform: uppercase;
    }

  }

  .conditions,
  .formulaire-montage,
  .recapitulatif-montage,
  .fichiers{
    padding: $margin-md;
    @media screen and (min-width: #{$md-large-screen}){
      padding: 0;
    }
  }


  .content,
  .conditions{
    @include checkbox;
    .checkbox{
      margin: $margin-sm 0;
      label{
        line-height: 29px;
        @media screen and (min-width: #{$md-large-screen}){
          line-height: inherit;
        }
      }
    }
  }

  .fichiers{
    .container-files{
      padding: $margin-xs $margin-sm;
      p{
        padding-left: 0;
      }
      .container-filename{
        max-width: calc(100% - 62px);
        p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .formulaire-montage{

    h3{
      color : $secondary-color;
      margin-bottom: $margin-sm;
    }

    .form{
      background-color: white;
      padding: $margin-md;
      border-radius: $radius;

      .select-items{
        margin-top: $margin-xs !important;

        li{
          width: 45%;
          max-width: 100px;
          @media screen and (min-width: #{$sm-screen}){
            width: auto;
          }

        }

      }

      .container-field{
        padding: $margin-xs 0;

        .switch-field{
          margin-top: $margin-sm;
          .switch{
            padding-right: 20px
          }
        }

      }

    }

    .col-3{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .container-input{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(50% - #{$margin-xs});
        input{
          margin-bottom: 0;
        }
        @media screen and (min-width: #{$sm-screen}){
          width: calc(33% - #{$margin-xs});
        }
      }
    }

    .container-coord{
      margin-top: $margin-sm;
    }

    .container-button{
      text-align: right;

      p{
        font-size: $small;
        width: 100%;
        text-align: center;
        color : $primary-color;
      }

      button.disabled .loader{
        opacity: .6;
        background-color: white !important;
      }
    }

    .form-coord{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: $margin-md 0 $margin-md 0;
      border-top: solid 1px $md-grey;

      &:last-child{
        padding: $margin-md 0 0 0;
      }

      p{
        width: 100%;
        margin-bottom: $margin-sm;
      }
      .container-input{
        width: calc(50% - #{$margin-xs});
      }

      .switch-field{
        p{
          margin-bottom: 8px;
        }
      }

    }

  }


  .recapitulatif-montage{
    .content{
      background-color: white;
      padding: $margin-md;
      border-radius: $radius;
      max-width: 600px;


      .container-btn{
        text-align: center;
      }

      .col-2,
      .col-3{
        display: flex;
        justify-content: space-between;
        border-top: solid 1px $md-grey;
        border-bottom: solid 1px $md-grey;
        margin: $margin-md 0 0 0;
        div{
          padding: 4px;
        }
        p{
          margin: $margin-xs 0;
        }
      }

      .col-2{
        border-top: none;
        margin-top: 0;
        margin-bottom: $margin-md;
        div{
          width: 50%;
          text-align: center;
          &.opt-active p {
            font-family: "MontSerrat-Medium";
            color: $primary-color;
          }
          &:not(.opt-active) p {
            opacity: 0.15;
          }
        }
      }

      .trucks{
        margin: 10px 0;
      }

      p.truck{
        margin-bottom: 0;
      }

      p span:not(.sub){
        font-family: "MontSerrat-Medium";
        color: $primary-color;
      }

      p.dot{
        position: relative;
        text-indent: 15px;
        &:after{
          content : "";
          position: absolute;
          top:5px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $primary-color;
          animation-iteration-count: 1;
          animation-duration: .3s;
          animation-delay: .3s;
          animation-name: fadeIn2;
          animation-fill-mode: forwards;
          transform: scale(0);
        }
        @keyframes fadeIn2 {
          0% { transform: scale(0); }
          100% { transform:  scale(1); }
        }
      }

      p.dot:nth-child(4):after{
        animation-delay: .6s;
      }

      img{
        display: block;
        max-width: 200px;
        margin: 0 auto;
      }

    }
  }

}
