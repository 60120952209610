.header{
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  max-height: 70px;
  padding: 0 $margin-sm;
  background-color: $light-grey;
  @media screen and (min-width: #{$md-screen}){
    background-color: $white;
  }

  .hide-on-tablet{
    display: inherit;
    @media screen and (max-width: #{$md-screen}){
      display: none !important;
    }
  }

  .only-on-tablet{
    display: inherit;
    @media screen and (min-width: #{$md-screen}){
      display: none !important;
    }
  }

  >a:first-child{
    text-decoration: none;;
    @media screen and (max-width: #{$md-screen}){
      margin-bottom: 0;
    }
  }

  li.inactive  a{
    cursor: not-allowed;
    color : $text-disabled-color !important;
  }

  @media screen and (min-width: #{$sm-screen}) {
    padding: 0 $margin-md;
  }

  @media screen and (min-width: #{$md-screen}) {
    max-height: $header-height;
  }

  p{margin: 0;}

  .logo{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img:nth-child(1){
      display: none;
      @media screen and (max-width: #{$md-screen}) {
        display: inline-block;
        height: 40px;
      }
    }

    img:nth-child(2){
      display: none;
      @media screen and (min-width: #{$md-screen}) {
        display: inline-block;
      }
    }

    h2{
      margin: 0;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      color : $secondary-color;
      margin-left: $margin-xs;
      white-space: nowrap;

      @media screen and (min-width: #{$sm-screen}) {
        font-size: $medium;
      }

      @media screen and (min-width: #{$md-screen}) {
        font-size: $h2;
      }

    }
  }

  @include burger();
  .burger{
    display: block;
    @media screen and (min-width: #{$md-screen}) {
      display: none;
    }
  }

  .profile{
    position: relative;
    z-index: 100;
    min-width: 220px;
    max-width: 300px;
    display: none;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    @media screen and (min-width: #{$md-screen}) {
      display: flex;
      flex-direction: column;
    }

    p{
      position: relative;
    }
    p img:first-child{
      position: absolute;
      z-index: 1;
      top:50%;
      right: -15px;
      display: inline-block;
      margin-left: $margin-xs;
      transform: translateY(-50%);
    }

    &:hover .dropdown{
      display: block;
    }

    >div{
      display: flex;
      width: 100%;
      height: $header-height;
      align-items: center;
      justify-content: flex-end;
    }

    >p{
      display: flex;
      height: $header-height;
      align-items: center;
      justify-content: center;
    }


    a,p{
      margin-bottom: 0;
    }
  }

  .dropdown-animations,
  .dropdown-informations,
  .dropdown-salon{
    position: relative;
    z-index: 100;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
    align-self: flex-start;
    transition: all .4s ease-in-out;

    &.open{
      background-color:$dark-blue;
      >a>img{
        transform: rotate(180deg);
      }
    }

    @media screen and (min-width: #{$md-screen}) {
      width: 120px;
      background-color:transparent;
      &.open{
        background-color:transparent;
      }
    }

    >a{
      position: relative;
      z-index: 0;
      display: flex;
      width: 100%;
      margin-bottom: 0;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      img{
        margin-left: $margin-xs;
        max-width: 20px;
        &:nth-child(2){
          display: none;
        }
        &:nth-child(1){
          display: block;
        }
        @media screen and (min-width: #{$md-screen}) {
          &:nth-child(2){
            display: block;
          }
          &:nth-child(1){
            display: none;
          }
        }

      }
    }


    @media screen and (min-width: #{$md-screen}) {
      // DESKTOP
      .dropdown li{
        padding: $margin-xs !important;
      }

    }

    @media screen and (max-width: #{$md-screen}) {
      &.open .dropdown{
        display: block;
      }
      .dropdown{
        width: 100%;
        margin-top: 0;
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        li{
          border-bottom: none !important;
          padding: $margin-sm 0 !important;
        }
      }
    }

    @media screen and (min-width: #{$md-screen}) {
      &:hover .dropdown{
        display: block;
      }
    }

  }

  .navigation{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (min-width: #{$md-screen}) {
      position: relative;
      height: $header-height;
    }

    @media screen and (max-width: #{$md-screen}) {
      &.open{
        position: fixed;
        top:0;bottom: 0;
        left:0;right:0;
        display: block;
        z-index: 100;
        overflow-y: auto;
        transition: all .4s ease-in-out;

        &:not(.profile-open){
          background-color: $secondary-color;
        }
        &.profile-open{
          background-color:$dark-blue
        }

        a,h2{
         color : $white;
         font-size: $medium;
        }

        img{
          height: 40px;
        }

        >ul li{
          display: block;
          padding: $margin-sm;
          margin: 0;
          box-sizing: border-box;
          border-bottom: solid 1px #1170CF;
        }

      }
      &:not(.open){
        display: none;
      }
    }

    .mobile-top-bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $margin-sm;
      height: 70px;
      @include close();

      .close{
        @include remove-tap-highlight;
        &:after,&:before{
          @include remove-tap-highlight;
        }
      }

      button{
        margin: 0;
      }


      @media screen and (min-width: #{$md-screen}) {
        display: none;
      }
    }

    ul{
      padding: 0;
      margin: 0;

      li.mobile-logout{
        position: relative;
        align-items: center;
        width: 100%;



        a{
          color: $white;
          margin: 0;
          padding-left: 40px;
          height: 100%;
        }
        img{
          position: absolute;
          top:50%;
          width: 30px;
          left:$margin-sm;
          transform: translateY(-50%);
        }
        @media screen and (min-width: #{$md-screen}) {
          display: none;
        }
      }

    }

    >ul{
      display: inline-block;
      @media screen and (max-width: #{$md-screen}) {
        display: block;
      }
      >li{
        display: inline-flex;
        align-items: center;
        margin: 0 $margin-xs;
        vertical-align: top;
        @media screen and (min-width: 1300px) {
          margin: 0 $margin-sm;
        }

        &.flash-sales{
          position: relative;
          a{
            position: relative;
            z-index: 2;
          }
          .flash{
            position: absolute;
            z-index: 1;
            top:28px;
            left:-12px;
            width: 18px;
            img{ width: 100%}
          }
        }

        &.li-orders{

          a.active{
            img:first-child{
              display: none;
            }
            img:last-child{
              display: inline-block;
            }
          }

          a:not(.active){
            img:first-child{
              display: inline-block;
            }
            img:last-child{
              display: none;
            }
          }

          img{
            position: relative;
            top: -1px;
            width: 14px;
            margin-right: 5px;
          }
        }

        >a{
          @media screen and (min-width: #{$md-screen}) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        a{
          height: inherit;
          @media screen and (min-width: #{$md-screen}) {
            height: $header-height;
          }
        }
      }
    }

    a{
      font-family: "Montserrat-SemiBold";
      color : $text-color;
      text-decoration: none;
      &.active{
        color : $primary-color;
      }
    }

  }

  .dropdown{
    padding: $margin-xs $margin-sm;
    box-sizing: border-box;
    background-color: $white;
    margin: 0;
    width: 220px;
    display: none;
    z-index: 1;

    @media screen and (min-width: #{$md-screen}) {
      margin-top: -20px !important;
    }

    @include shadow();

    li.logout{
      &:hover a{
        color: $primary-color;
        cursor: pointer;
      }
      img{
        position: relative;
        top:3px;
        margin-right: 5px;
      }
    }

    li:not(.inactive) a:hover{
      text-decoration: underline;
    }

    li{
      list-style: none;
      padding: $margin-xs 0;

      a{
        font-family: "Montserrat-Regular";
        color: $text-color;
        text-decoration: none;

        &.active{
          color: $primary-color;
        }
      }

      &:not(:last-child){
        border-bottom: solid 1px #E8F1F9;
      }
    }
  }

  .initialed-user{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    background-color: $secondary-color;
    color:white;
    margin-right: $margin-sm;
    border-radius: $radius;

    p{ margin: 0}
  }

  @include Tiles();
  .tiles{
    display: block;
    @media screen and (min-width: #{$md-screen}) {
      display: none;
    }
  }

  .tiles{
    a .top-right{
      font-size: 16px !important;
    }
  }

}
