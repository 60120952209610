@import "./variables.scss";
@import "./_mixins.scss";
@import "./_sliderPages.scss";

@import "./base.scss";
@import "./toast.scss";
@import "./partials/tiles.scss";
@import "./partials/header.scss";
@import "./partials/slider.scss";
@import "./partials/validate-order.scss";

@import "./fields/input-field.scss";
@import "./fields/switch-field.scss";
@import "./fields/select-items.scss";
@import "./fields/upload-doc.scss";

@import "./auth/auth.scss";
@import "./auth/forgot-password.scss";
@import "./pages/store/store.scss";
@import "./pages/orders/orders.scss";
@import "./pages/orders/order.scss";
@import "./pages/badges/badges.scss";
@import "./pages/products-provider/products-provider.scss";
@import "./pages/products/products.scss";
@import "./pages/products/product.scss";
@import "./pages/salon/salon.scss";
@import "./pages/trophy/trophy.scss";
@import "./pages/animations/animations.scss";
@import "./pages/list-providers/list-providers.scss";
@import "./pages/programme/programme.scss";
@import "./pages/plan/plan.scss";
@import "./pages/quizz/quizz.scss";

@import "./pages/avis/avis.scss";
@import "./pages/montage/montage.scss";
@import "./pages/informations/informations.scss";
@import "./pages/informations/transports.scss";
@import "./pages/informations/horaires.scss";
@import "./pages/informations/cocktail.scss";
@import "./pages/informations/restaurations.scss";
@import "./pages/informations/downloadfile.scss";

@import "./pages/stand/stand-form.scss";
@import "./pages/stand/step1.scss";
@import "./pages/stand/step2.scss";
@import "./pages/stand/recapitulatif.scss";
@import "./pages/stand/submit-succeed.scss";
@import "./pages/stand/support-contact.scss";
@import "./pages/cgu.scss";


@import "./pages/contributors/contributors.scss";
@import "./pages/subscription/subscribe.scss";

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $light-grey;
}

body,*{
  font-family: "Montserrat-Regular";
  box-sizing: border-box;
}

#root, .App, .App>div {
  width: 100%;
  height: 100%;
}

.App{
  font-family: sans-serif;
  overflow: hidden;
}

.page{
  position: absolute;
  width: 100%;
}

.content{
  position: relative;
  box-sizing: border-box;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  &.large{
    max-width: 1224px;
  }
  @media screen and (min-width: #{$sm-screen}) {
    width: 90%;
  }
}

.content-large{
  position: relative;
  box-sizing: border-box;
  max-width: 1124px;
  margin: 0 auto;
  width: 100%;
  &.large{
    max-width: 1224px;
  }
  @media screen and (min-width: #{$sm-screen}) {
    width: 90%;
  }
}

h1{
  font-family: "Montserrat-SemiBold";
  font-size: $h1;
  margin: $margin-sm 0;
}


h2{
  font-family: "Montserrat-SemiBold";
  font-size: $h2;
  margin: $margin-sm 0;
}

.title-page{
  padding-top: $margin-sm;
  width: 100%;
  @media screen and (max-width: #{$md-screen}) {
    display: none !important;
  }
}

.hide-on-mobile{
  display: inherit;
  @media screen and (max-width: #{$sm-screen}){
    display: none !important;
  }
}

.only-on-mobile{
  display: inherit;
  @media screen and (min-width: #{$sm-screen}){
    display: none !important;
  }
}

.hide-on-tablet{
  display: inherit;
  @media screen and (max-width: #{$md-screen}){
    display: none !important;
  }
}

.only-on-tablet{
  display: inherit;
  @media screen and (min-width: #{$md-screen}){
    display: none !important;
  }
}

h3{
  font-family: "Montserrat-Regular";
  font-size: $medium;
  margin: $margin-sm 0;
}

hr{
  color: $light-grey;
  background: $light-grey;
  border-color: $light-grey;
}

label, a{
  @include remove-highlight();
}

p, label, a, input, textarea{
  font-family: "Montserrat-Regular";
  font-size: $regular;
  margin: 0 0 $margin-xs 0;
  .sub{
    color: $text-disabled-color
  }
  &.warn{
    color: $primary-color;
    font-family: "Montserrat-SemiBold";
  }
  &.succeed{
    font-family: "Montserrat-SemiBold";
    color: #00BA38;
  }
}


p.medium,span.medium{
  font-family: "MontSerrat-Medium";
}
