.orders, .order{
  @include listProducts();

  .list-products{

    padding-bottom: 0;

    @media screen and (max-width: #{$md-screen}){
      .product-name{
        width: 50%;
        margin-bottom: $margin-sm;
      }

      .product-price{
        width: 100%;
        margin-bottom: $margin-sm;
        >div{
          display: flex;
          justify-content: space-between;
        }

        .mobile-segment-ref{
          p{ font-size: $regular}
          p:first-child{
            color : $text-color;
            font-family: "Montserrat-SemiBold"
          }
          p:last-child{
            color : $md-grey;
            font-family: "Montserrat-Regular"
          }
        }


      }

      .product-qty{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    }

    .product-qty{
      padding: 0;
    }

    .product-total{


      &:not(.readonly){
        width: 90px !important;
        min-width: 90px !important;
      }

      &.readonly{
        width: 110px !important;
        min-width: 110px !important;
        p{
          font-size: 20px;
        }
      }

      &:hover p{
        overflow: inherit;
      }



      p{
        font-family: "Montserrat-Bold";
        color : $secondary-color;
        text-align: right;
        width: 100%;
        word-break: break-word;
      }
    }

    @media screen and (min-width: #{$md-screen}){
      >ul li{
        .product-packing p{
          text-align: center;
          width: 54px;
        }
      }

      .product-name .product-ref,
      .product-qty .product-packing,
      .product-qty .product-segment{
        display: none;
      }


    }

  }
}
