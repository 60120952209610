.container-files{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: wrap;
  background: $white;
  width:100%;
  flex:1 1 100%;
  margin: $margin-xs 0;
  padding: $margin-sm;
  border-radius: $radius;
  @include shadow();

  @media screen and (min-width: #{$md-screen}) {
    flex-direction: row;
  }

  >img{
    width: 100%;
    margin-bottom: $margin-sm;
  }


  a{
    color: $white;
    text-decoration: none;
  }


  button{
    margin: 0;
    min-width: auto;
    img{
      position: relative;
      top:2px;
      margin : 0 -15px;
    }
  }

  .container-filename{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: #{$md-screen}) {
      flex-direction: row;
    }

    p{
      margin: 0 !important;
      padding: $margin-sm;
      text-align: center;
      @media screen and (min-width: #{$md-screen}) {
        text-align: left;
      }
      &.filename{
        font-family: "MontSerrat-SemiBold";
        padding-right: $margin-md;
        @media screen and (min-width: #{$md-screen}) {
          border-right: solid 1px $md-grey;
        }
        +p{
          padding-left: $margin-md;
        }
      }

    }
  }
}
