.transports{

  .col{

    .container-files{
      flex-direction: row;
      button{
        width: 40px;
        height: 35px;
        padding: 0;
      }
      p{
        text-align: left;
        padding: 0;
        width: 100%;
        font-size: 16px;
        font-family: "MontSerrat-Regular";
      }
    }

    &:first-child{
      .container-files:nth-child(2){
        display: flex ;
        @media screen and (min-width: #{$md-screen}) {
          display: none;
        }
      }

    }

    &:nth-child(2){

      .container-files{
        display: none;
        @media screen and (min-width: #{$md-screen}) {
          display: flex;
        }
      }

    }

  }
}
