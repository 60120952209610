.list-providers{
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;
  overflow-y: auto;

  .container-filters{
    top: $height-nav;
    position: fixed;
    z-index: 2;
    margin-bottom: $margin-xs;
    padding: 0 $margin-xs;
    background-color: $light-grey;
    width: 100%;
    max-width: 1024px;

    @media screen and (min-width: #{$sm-screen}) {
      width: 90%;
    }

    .select-domains,
    .search-provider{
      display: block;

      input{
        margin-bottom: 0;
      }

    }

    .select-domains{
      margin: $margin-xs 0 0 0 ;
    }

    @media screen and (min-width: #{$md-screen}) {
      top: 90px;
      padding: $margin-sm $margin-xs;
      .select-domains,
      .search-provider{
        display: inline-block;
        min-width: 400px;
      }

      .select-domains{
        margin: 0 0 0 $margin-xs;
      }

    }


  }


  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: calc(100px + #{$margin-xs});
    @media screen and (min-width: #{$md-screen}) {
      margin-top: 90px;
    }
  }

  li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
    margin: $margin-xs;
    padding: $margin-xs;
    border-radius: 8px;
    border-radius: 8px;

    @include shadow();

    &:first-child{
      margin-top: 0;
    }

    .showroom-number{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $md-grey;
      min-width: 60px;
      height: 45px;
      border-radius: 8px;
      margin-right: $margin-xs;

      @include pastille();

      .pastille{
        width: 70px;
        height: 45px;
        min-width: 70px;
        min-height: 45px;
        &.expert{
          min-width: 80px;
          width: 80px;
        }
      }

      p{
        position: relative;
        z-index: 1;
        color: $white;
      }
    }

    p{
      font-family: "Montserrat-SemiBold";
      margin: 0;
      color : $text-color;
    }

  }

}
