.submit-succeed{
  position: absolute;
  width: 100%;
  height: 100%;

  button{
    margin-top: $margin-sm;
  }

  >div:nth-child(1){
    position: absolute;
    z-index: 2;
    top:5%;
    width: 100%;
    left:0;
    text-align: center;
    padding: $margin-xs;

    @media screen and (min-width: #{$sm-screen}) {
      top:50%;
      width: auto;
      text-align: left;
      padding: 0;
      transform: translateY(-50%);
    }

  }

  >div:nth-child(2){
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:-60%;
    height: 75%;

    img{
      height: 100%;
    }

    @media screen and (min-width: #{$sm-screen}) {
      height: auto;
      right:0;
      img{
        height: auto;
      }
    }

  }


}
