
@mixin sliderPages(){
  .slider-pages{
    $height-nav : 64px;
    width: 100%;
    height: 100%;

    >div{
      width: 100%;
      height: 100%;
    }

    .page-navigation{
      position: fixed;
      display: flex;
      justify-content: space-around;
      max-width: 400px;
      bottom: 0;
      left:50%;
      width: 100%;
      z-index: 1;
      text-align: center;
      padding: $margin-md 0 $margin-sm 0;
      height: $height-nav;
      white-space: nowrap;
      transition: all .4s ease-in-out;
      transform: translateX(-50%);

      @media screen and (min-width: 485px) {
        bottom: $margin-md;
      }

      a{
        position: relative;
        display: block !important;
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        border-radius: 7px;
        background-color: $md-grey;

        &.disabled{
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.checked:after{
          position: absolute;
          content : '';
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
          width: 18px;
          height: 18px;
          border-radius: 9px;
          border: solid 1px $md-grey;
        }

        &.active{
          background-color: $primary-color;
          &.checked:after{
            border-color: $primary-color;
          }
        }
        @include shadow;
      }

    }

    .container-pages{
      position: absolute;
      width: 100%;
      min-height: 100%;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0 $margin-sm $margin-md $margin-sm;

      @media screen and (min-width: 485px) {
        padding: 0 0 $margin-md 0;
      }

      @media screen and (min-width: #{$md-screen}) {
        padding: $margin-md 0;
      }



    }

  }

}

@mixin Question(){
  >p{
    font-family: "Montserrat-Bold";
    color: $text-color;
    text-align: center;
    margin-bottom: $margin-md
  }

  ul{
    padding: 0;
    li{
      display: block;
      list-style-type: none;

      .answer-text{
        display: block;
        margin: 40px auto;
        cursor: pointer;
        text-align: center;
        color: $white;
        background-color: $secondary-color;
        max-width: 240px;
        padding: $margin-sm 0;
        border-radius: $radius;
        transition: all .3s ease-in-out;
        &:hover{
          background-color: lighten($secondary-color,10%);
        }
      }

      img{
        display: block;
        margin: $margin-sm auto;
        cursor: pointer;
        width: 45px;
        @media screen and (min-height: 600px) {
          width: auto;
        }
      }
    }
  }
}
