.cgu{
  overflow-y: auto;
  padding-bottom: $margin-sm;
  .header{
    padding: $margin-sm $margin-xs;
  }

  button{
    position: fixed;
    top:10px;
    right : 10px;
    margin-bottom: 0;
    @media screen and (min-width: #{$md-screen}) {
      top:25px;
      right : 25px;
    }
  }

  a{
    // color: white;
    text-decoration: none;
  }

  @media screen and (max-width: #{$md-screen}) {
    h2{
      margin-top: 0;
    }
  }

  h2,h3{
    color : $text-color;
  }

  h3{
    font-family: "Montserrat-SemiBold";
  }

  .content{
    padding: 0 $margin-sm;
  }
}
