.switch-field{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  p{
    font-size: $regular;
    font-family: "Montserrat-SemiBold";
    .sub{
      font-family: "Montserrat-Regular"
    }
  }

  .switch{
    padding-right: $margin-md;
  }

}
