.container-support{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $margin-md 0;
  padding: $margin-sm;

  @media screen and (min-width: $sm-screen) {
    padding: $margin-sm 0;
  }

  @media screen and (min-width: 1124px) {
    padding: $margin-sm 0;
  }

  button,a{
    margin-bottom: 0;
  }

}

.support-contact{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  h3,p{
    margin: 0 5px;
    font-size: $small;
    white-space: nowrap;
    a{
      color: $text-color;
      text-decoration: none;
    }
  }

  .required{
    width: 100%;
    margin-bottom: $margin-xs;
    span{
      color: $primary-color;
    }
  }

  h3{
    font-family: "Montserrat-Bold"
  }

  img{
    position: relative;
    top:3px;
    margin: 0 5px;
  }

  .btn-blue{
    background-color: $secondary-color;
    border-color: $secondary-color;
    width: 45px;
    min-width: 45px;
    padding: 0;
  }

}

.modal-support{
  position: fixed;
  z-index: 100;
  width: 100%;
  top:0;
  height: 100%;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .back{
    position: absolute;
    top:20px;
    left: 20px;
    margin: 0;
    font-family: "Montserrat-SemiBold";
    font-size: $regular;
    cursor: pointer;
  }

  h3,p,a{
    color: white;
  }

  h3,
  .phone a,
  .email a{
    font-family: "Montserrat-Bold";
    font-size : 18px;
    margin: 10px 0;
    text-decoration: none;
  }

  p{
    margin: 20px 0;
    text-align: center;
    line-height: 24px;
    font-size : 18px;
  }

  img{
    position: relative;
    top:4px;
    width: 25px;
    margin: 0 15px;
  }

}
