.restaurations{
  justify-content: flex-start;

  .header-trucks {
    width: 100%;
    margin-bottom: $margin-sm;
    h2 {
      font-family: 'MontSerrat-SemiBold';
      color: $primary-color;
      margin: 0;
    }
    p{
      margin: 0;
    }
  }

  .foodTrucks{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $white;
    @include block();
    @media screen and (min-width: $md-screen) {
      width: calc(67% - 10px);
    }

    .bold {
      font-family: 'MontSerrat-SemiBold';
      color: $secondary-color;
      margin-bottom: $margin-sm;
    }

    .header-trucks {
      text-align: center;
    }

    .visual-truck,
    .foodTruck{    
      position: relative;
      width: 100%;

      @media screen and (min-width: $md-screen) {
        width: 50%;
        &:nth-child(2){
          padding-right: $margin-xs;
        }
        &:nth-child(3){
          padding-left: $margin-xs;
        }
      }
    }

    .visual-truck+p {
      width: 100%;
      text-align: center;
    }

    .foodTruck {
      min-height: 100%;

      h3 .hightlight{
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
        color: $primary-color;
      }

    }

    .visual-truck{
      margin-bottom: $margin-xs;
      .flash {
        position: absolute;
        right: 0;
        left: auto;
        top: 0;
        width: 45px;
        transform: translate(50%, -35%);
      }

      >img {
        max-width: 100%;
        min-height: 150px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

  }

  .foodTrucks-mobile{
    display: flex;
    flex-direction: column;
    .header-trucks {
      h2{
        margin-bottom: 5px;
      }
      .sub{
        margin: $margin-xs 0;
        font-family: 'Montserrat-SemiBold';
        color: $secondary-color;
      }
      // margin-bottom: $;
    }
    >div {
      width: 100%;
    }
  }

}
