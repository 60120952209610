.select-items{
  ul{
    padding: 0;
    margin: 0;

    &.loading li{
      padding: 0;
      font-size: 0;
      margin: 0;
    }

    &:not(.loading) li{
      padding: $margin-xs;
      border: solid 1px $md-grey;
      margin-bottom: 10px;

      &:not(:last-child){
        margin-right: $margin-xs;
      }

      &.selected{
        border-color: $secondary-color;
        background-color: $secondary-color;
        color:$white;
      }

    }

    li{
      display: inline-block;
      font-size: $regular;
      color: $text-color;
      border-radius: $radius;
      cursor: pointer;
      outline: none;
      @include remove-highlight();

      &.disabled{
        opacity: 0.4;
        pointer-events: none;
      }

      img{
        height: 12px;
        margin-left: 3px;
      }
    }

  }
}
