.salon{
  overflow-y: auto;
  height: calc(100% - 70px) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  h1,h2,p,li{
    color : $secondary-color;
  }

  h1{
    font-size: $h1;
    margin-top: 0;
  }

  // .list-nouveau{
  //   margin-top: 3px;
  //   li.dot{
  //     margin: 0 0 3px 0;
  //     padding-left: 5px;
  //     &:before{
  //       background-color: $secondary-color;
  //     }
  //   }
  // }

  ul{
    padding: 0;
    list-style: none;
    li.dot{
      position: relative;
      padding-left: 10px;
      margin: 0 0 $margin-xs 0;
      &:before {
        content: "";
        position: relative;
        top: -1px;
        left: -9px;
        display: inline-block;
        height: 6px;
        width: 6px;
        display: inline-block;
        border-radius: 3px;
        background-color: $primary-color;
      }
      font-size: $regular;
    }
  }

  h2{
    font-family: "MontSerrat-Regular";
    margin-bottom: 0;
    margin-top: 0;
    font-size: $medium;
  }

  @media screen and (max-width: #{$md-screen}) {
    .mobile-centered{
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }


  @media screen and (min-width: #{$md-screen}) {
    h2{
      font-size: $h2;
      margin-top: $margin-sm;
    }
    h1{
      font-size: $xl;
    }
  }

  b{
    font-family : "MontSerrat-SemiBold"
  }

  p{
    margin-bottom: $margin-sm;
    &.bold{
      font-family : "MontSerrat-SemiBold"
    }
  }

  .content{
    position: relative;
    height: auto;
    box-sizing: border-box;
    padding: 0 $margin-sm;
    height: 100%;

    @media screen and (min-width: #{$md-screen}) {
      padding: 0;
    }

    .container-col {
      position: relative;
      width: 100%;
      transform: translateY(0);

      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: column;
      }

      @media screen and (min-width: #{$md-screen}) {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        flex-direction: row;
      }
    }

    .col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      width: 100%;
      padding: 0 $margin-sm;

      >div{
        width: 100%;
      }

      @media screen and (min-width: #{$md-screen}) {
        &:first-child{
          width:40%;
        }
        &:last-child{
          width:60%;
        }
      }


    }

    .video{
      position: relative;
      width: 100%;
      padding-top: 75%;
      // padding-top: 56.25%;
      background-color: lighten($md-grey,22%);
      border-radius: $radius;
      overflow: hidden;
      margin-bottom: $margin-sm;
      font-size: 0;
      @media screen and (min-width: #{$sm-screen}) {
        margin-bottom: 0;
      }

      img{
        object-fit: cover;
        height: 100%;
      }

      img,
      video{
        position: absolute;
        top:0;
        width: 100%;
      }
    }

  }

  .support-contact{
    position: relative;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: $margin-sm 0;
    @media screen and (min-width: #{$md-screen}) {
      padding: $margin-sm $margin-md;

      justify-content: center;
      position: absolute;
      bottom: 0px;
    }

  }

  .plan{
    position: relative;
    overflow: hidden;
    border-radius: $radius;
    margin: $margin-sm 0;
    font-size: 0;

    a{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(22,27,70,.45);
      font-family : "Montserrat-SemiBold";
      font-size: $medium;
      color: $white;
      text-align: right;
      padding: $margin-sm;
    }

    @include shadow();
    img{
      width: 100%;
    }
  }

  @include Tiles();
  .tiles{
    max-width: 500px;
    margin : 0 auto;
    ul{
      padding: 0;
      margin-bottom: $margin-sm;
    }
    .tile{
      width: calc(100%/2);
      padding-top: calc(100%/2);
      &:nth-child(1) div,
      &:nth-child(3) div{
        padding-left: 0;
      }

      &:nth-child(2) div,
      &:nth-child(4) div{
        padding-right: 0;
      }

      a{
        background-color: $secondary-color;
      }
    }
  }

}
