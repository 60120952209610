.container{
  position: relative;
  width:100%;
  background-size: cover;
  overflow: hidden;
  background-color: white;

  .container-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .no-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  img{
    width: 100%;
    height: auto;
  }
}