.programme {
  $height-nav: 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  .container-programme-navigation {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: $light-grey;
  }

  .programme-navigation {
    text-align: center;
    padding: $margin-xs 0 $margin-sm 0;
    height: 50px;
    white-space: nowrap;
    transition: all 0.4s ease-in-out;

    @media screen and (min-width: #{$md-screen}) {
      padding: $margin-md 0 $margin-sm 0;
    }

    a {
      font-family: "MontSerrat-SemiBold";
      color: $text-color;
      text-decoration: none;
      margin: 0 2%;
      @include remove-tap-highlight;

      &.active {
        color: $primary-color;
      }
    }
  }

  .container-programme {
    position: absolute;
    width: 100%;
    top: 0px;
    min-height: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding-top: $margin-md;

    @media screen and (min-width: #{$md-screen}) {
      padding-top: calc(#{$height-nav} - #{$margin-sm});
    }
  }

  .conferences,
  .animations {
    &.content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: inherit;
      padding: $margin-md;
    }
  }

  .conferences {
    margin-top: $margin-sm;
    max-width: 600px;

    .title-conference { 
      display: flex;

      img {
        position: relative;
        top: 3px;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin-right: 10px;
      }
    }

    .conferences-list .conference:nth-child(even):not(:nth-child(2)) {
      background-color: #ced7ee !important;
    }

    .conference {
      position: relative;
      padding: $margin-md;
      border-radius: 8px;
      margin-bottom: $margin-md;
      @include shadow();

      h2 {
        font-family: "MontSerrat-SemiBold";
        font-size: $regular;
        margin: 0;
      }
      p {
        font-size: $small;
      }

      .label {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);
        border-radius: 8px;
        font-family: "MontSerrat-SemiBold";
        padding: $margin-xs $margin-sm;
        border-width: 2px;
        border-style: solid;
        background-color: $white;
      }

      &:not(.electro):not(.multi) {
        background-color: $white;
        h2 {
          color: $secondary-color;
          span:first-child {
            font-family: "MontSerrat-SemiBold";
          }
          span:nth-child(2) {
            display: inline-block;
            margin-top: 3px;
          }
        }
      }

      &.electro {
        background-color: $primary-color;
        .label {
          border-color: $primary-color;
          color: $primary-color;
        }
        h2, p {
          color: $white;
        }
      }

      &.multi {
        background-color: $secondary-color;
        .label {
          border-color: $secondary-color;
          color: $secondary-color;
        }
        h2, p {
          color: $white;
        }
      }
    }

  }

  .animations {
    .block {
      @include block();

      .title-mobile.open {
        margin-bottom: $margin-xs;
      }

      .container-img {
        width: 100%;
        max-width: $sm-screen;
        overflow: hidden;
        border-radius: 8px;
        font-size: 0;
        &:not(:last-child) {
          margin-bottom: $margin-sm;
        }
        img {
          width: 100%;
          margin: 0;
        }
      }

      h3 {
        margin: 0;
        &:after {
          display: none !important;
        }
      }

      .banner {
        font-family: "MontSerrat-SemiBold";
        color: $text-color;
        margin-bottom: $margin-xs;
        &.hide-on-desktop {
          @media screen and (min-width: #{$sm-screen}){
            display: none !important;
          }
        }
      }

      .sub-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 3px;
        div,
        p {
          font-family: "MontSerrat-SemiBold";
          color: $text-color;
          display: inline-block;
          margin: 0;
        }

        .banner {
          font-size: $small;
        }

        .stand {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $md-grey;
          color: $white;
          border-radius: 8px;
          height: 40px;
          min-width: 60px;
          min-height: 40px;

          padding: 5px;
          margin-right: $margin-xs;

          p {
            position: relative;
            z-index: 1;
            color: $white;
          }

          @include pastille();

          .pastille {
            width: 60px;
            height: 40px;
            min-width: 60px;
            min-height: 40px;
            &.expert {
              min-width: 80px;
              width: 80px;
            }
          }
        }
      }
    }
  }
}
