.trophy{
  overflow-y: auto;
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  .content{
    padding: $margin-md;
    margin: $margin-md;
    width: calc(100% - #{$margin-md}*2);
    max-width: 425px;
    background: white;
    border-radius: $radius;
    @include shadow();

    @media screen and (min-width: #{485px}) {
      margin: $margin-md auto;
      width: 100%;
    }

    h3{
      color: $text-color;
    }

    a{
      color: $text-color;
    }

    .MuiCircularProgress-root{
      position: relative;
    }

    img{
      display: block;
      max-width: 180px;
      margin: 0 auto;
    }

    h1{
      position: relative;
      color: $text-color;
      text-align: center;
      padding-bottom: $margin-sm;
      margin-bottom: $margin-md;
      &:after{
        content : "";
        position: absolute;
        height: 2px;
        width: 50%; max-width: 100px;
        bottom: 0;
        left:50%;
        transform: translateX(-50%);
        background-color: $primary-color;
      }
    }

    .vote-done{
      text-align: center;
    }

    .container-vote{
      max-width: 425px;
      margin: 0 auto;
      label{
        font-family: "Montserrat-SemiBold";
        display: block;
      }

      .css-2b097c-container{
        margin-bottom: $margin-sm;
      }
      p{
        font-family: "Montserrat-SemiBold";
        color: $text-color;
        margin-top: $margin-md;
      }

    }


  }
}
