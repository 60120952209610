@import '../../../../scss/variables.scss';

.inputFileContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;


  .dropZone {
    border: 1px dashed $md-grey;
    padding: 10px;
    transition: background-color 0.3s;
    max-width: 230px;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.4;
    }

    &:not(.hasImage) {
      display: flex;
      align-items: center;
      height: 100px;
      text-align: center;
      p {
        font-size: $small;
        color: $md-grey;
        font-family: "Montserrat-SemiBold";
        width: 120px;
      }
    }
  }
  
  .dropZone.hasImage {
    background-color: #f0f0f0;
    font-size: 0;
  }
  
  .image-container {
    position: relative;
  }
  
  img {
    max-width: 100%;
    max-height: 200px;
  }
  
  .inputFile {
    display: none;
  }

  .info {
    p {
      font-family: "Montserrat-SemiBold";
      margin-left: $margin-sm;
      text-decoration: underline;
    }

    label {
      font-family: "Montserrat-SemiBold";
      margin-left: $margin-sm;
      text-decoration: underline;
      color: $secondary-color !important;
      cursor: pointer;
    }

    .error {
      font-family: "Montserrat";
      flex: 1;
      width: 100%;
      color: $error !important;
      text-decoration: none;
    }
    
    .delete {
      color: $error;
      margin-left: $margin-sm;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

