.stand-form{
  position: absolute;
  top:$header-height;
  height: calc(100% - #{$header-height}) !important;
  width: 100%;

  .nav-provider-from{
    padding: 0 $margin-sm;
    box-sizing: border-box;
    // max-width: $sm-screen;
    width: 100%;

    @media screen and (min-width: #{$sm-screen}) {
      padding: $margin-sm;
    }

    >.title{
      position: relative;
      margin-bottom: $margin-md;

      display: none;
      @media screen and (min-width: #{$sm-screen}) {
        display: block;
      }

      h2{
        font-size: 20px;
      }
      @media screen and (min-width: #{$sm-screen}) {
        h2{
          font-size: $h2;
        }
      }

      .initialed-user{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right:0;
        top:50%;
        transform: translateY(-50%);
        width: 35px;
        height: 35px;
        background-color: $secondary-color;
        color:white;
        border-radius: $radius;
        p{ margin: 0}
      }
    }

    &.loading ul:after{
      display: none;
    }

    ul{
      position: relative;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;

      &:after{
        content: "";
        position: absolute;
        z-index: 0;
        border-top: dashed 1px $primary-color;
        width: 100%;
      }

      .nav-step{
        z-index: 2;
        position: relative;
        display: inline-block;
        border: solid 1px $primary-color;
        width: 40px;
        height: 40px;
        border-radius: 20px;

        .checkmark{
          position: absolute;
          bottom: -9px;
          right: -9px;
          background: white;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          img{
            width: 20px;
          }
        }



        &.disabled{
          pointer-events: none;
          border-color: lighten($primary-color, 30%);
          a{
            color : lighten($primary-color, 30%) !important;
          }
        }

        &:after{
          content: "";
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        &.active{
          background-color: $primary-color;
          a{ color:$white; }
          &:after{
            background-color: $primary-color;
          }
        }

        &:not(.active){
          a {
            background-color: $light-grey;
            color: $primary-color;
          }
          &:after{
            background-color: $light-grey;
          }
        }

        .sub-nav{
          position: absolute;
          display: flex;
          justify-content: space-between;
          top:-14px;
          left:50%;
          transform: translateX(-50%);
          width: 24px;
          .dot{
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: lighten($primary-color,20%);
            &.selected{
              background-color: $primary-color;
            }
          }
        }

        >a{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
          text-decoration: none;
          font-size: 15px;
          @include remove-tap-highlight;
        }
      }
    }
  }

  .container-step{
    position: absolute;
    width: 100%;
    top:50px;
    bottom: 0;

    @media screen and (min-width: #{$sm-screen}) {
      top:70px;
    }

    @media screen and (min-width: #{$md-screen}) {
      top:148px;
    }

    overflow-y: auto;
    .content{
      position: relative;
      height: 100%;
    }
  }


  .section-title{
    font-family: "Montserrat-SemiBold";
    padding: $margin-sm $margin-md;
    .required{
      color : $primary-color;
    }
  }

  section{
    background: $white;
    padding: 5px $margin-md;
    margin-bottom: $margin-sm;
    max-width: 1024px;
    border-radius: 0;
    box-shadow: $shadow;
    @media screen and (min-width: #{$sm-screen}) {
      border-radius: $radius;
    }
  }

  section button.secondary{
    margin-top: $margin-xs;
  }

  label{
    .required{
      color: $primary-color;
    }
  }

}
