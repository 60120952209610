.cocktail{
  .block{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto $margin-sm auto;

    >div{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: $margin-md 0 0 0;
    }

    .info{
      font-family: "Montserrat-SemiBold";
      color : $primary-color;
      margin-top: $margin-sm !important;
    }

    .sub{
      font-style: italic;
      color : $text-disabled-color;
      margin-top: $margin-sm !important;
    }

    @media screen and (min-width: $sm-screen){
      flex-direction: row;

      >div{
        width: 25%;
        margin: 0;
      }
    }

    >h3{
      align-self: flex-start;
      font-size: 16px;
      width: 100%;
      margin-bottom: $margin-md;

      @media screen and (min-width: $md-screen){
        font-size: $medium;
        width: 25%;
        margin: 0;
      }

    }

    >div{
      flex: 1 2;
      img{
        margin: 0 auto;
        min-width: 40px;
      }
      p{
        text-align: center;
        margin: $margin-sm;
      }
    }

  }
  .block{
    div:nth-child(2) {
      @media screen and (min-width: $sm-screen){
        max-width: 300px;
        padding-right: $margin-md;
      }

      @media screen and (min-width: $md-screen){
        max-width: 300px;
      }
      img{
        display: block;
        width: 100%;
        margin: 0;
        @media screen and (max-width: $md-screen){
          max-width: 400px;
        }
      }

    }
    div:nth-child(3){
      flex-direction: row;
      max-width: 100%;
      p{
        text-align: left;
      }
      @media screen and (min-width: $sm-screen){
        max-width: 15%;
        flex-direction: column;
        img{
          margin: 0 auto;
        }
        p{
          text-align: center;
        }
      }
      img{
        margin: 0;
        width: 50px;
        min-height: 50px;
        display: block;
      }
    }

    div:last-child{
      @media screen and (min-width: $sm-screen){
        width: 50%;
      }
      p{
        text-align: left;
        margin: 0;
      }
      h3{
        font-family: 'Montserrat-SemiBold';
        color: $primary-color;
        width: 100%;
        font-size: 16px;
        &:after{ display: none;}
        @media screen and (min-width: #{$sm-screen}){
          font-size: $regular;
        }
      }
    }
  }

}
